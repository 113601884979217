// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import TextLoop from 'react-text-loop'
// import { Link } from 'gatsby'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'
import Video from '../components/Video'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Year book 2023-24',
  nakedPageSlug: 'yearbook',
  pageAbstract: 'Content being added at the moment.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = () => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1 style={{ marginBottom: 0 }}>YEAR BOOK 2023-24</h1>
    <br/>
    <Row gutter={{ xs: 12, sm: 18, md: 24, xl: 36 }}>
      <Col sm={24} md={12}>
        <Video
          url="https://youtu.be/1qIv7Ucozp8?si=4YQ-pp63czN-OMoJ"
          style={{ marginBottom: 30 }}
        />
      </Col>
      <Col sm={24} md={12}>
        <Video
          url="https://youtu.be/Kh-8D5SZI4c?si=xfyyALVOfck4gjeD"
          style={{ marginBottom: 30 }}
        />
      </Col>
    </Row>
    <Row gutter={{ xs: 12, sm: 18, md: 24, xl: 36 }}>
      <Col sm={24} md={12}>
        <Video
          url="https://youtu.be/6XjlHzL9AR4?si=kfF4rAzifHpOIBL5"
          style={{ marginBottom: 30 }}
        />
      </Col>
      <Col sm={24} md={12}>
        <Video
          url="https://youtu.be/Wra_2qkh5Io?si=szjGsedPCxUUXJ-q"
          style={{ marginBottom: 30 }}
        />
      </Col>
    </Row>

    <Row gutter={{ xs: 12, sm: 18, md: 24, xl: 36 }}>
      <Col sm={24} md={12}>
        <Video
          url="https://youtu.be/dN-vqErYvho?si=-bqIEoO9JlkmoTkB"
          style={{ marginBottom: 30 }}
        />
      </Col>
      <Col sm={24} md={12}>
        <Video
          url="https://youtu.be/L5gd8DeC8WQ?si=J2jEz98qxErXkNxE"
          style={{ marginBottom: 30 }}
        />
      </Col>
    </Row>

    <Row gutter={{ xs: 12, sm: 18, md: 24, xl: 36 }}>
      <Col sm={24} md={12}>
        <Video
          url="https://youtu.be/STv6GumqRKQ?si=_B8HmAVllBUcHDHE"
          style={{ marginBottom: 30 }}
        />
      </Col>

      <Col sm={24} md={12}>
        <Video
          url="https://youtu.be/93317REOHZE?si=bKg_f-by3uDHwy2N"
          style={{ marginBottom: 30 }}
        />
      </Col>
      
    </Row>

    <Row gutter={{ xs: 12, sm: 18, md: 24, xl: 36 }}>
      <Col sm={24} md={12}>
        <Video
          url="https://youtu.be/Nh9CN-6LgW0?si=osqorI7g64RsvZ39"
          style={{ marginBottom: 30 }}
        />
      </Col>

      <Col sm={24} md={12}>
        <Video
          url="https://youtu.be/c0ndDcRq_yA?si=izFO3AGxUMUvdo5_"
          style={{ marginBottom: 30 }}
        />
      </Col>
      
    </Row>

    <Row gutter={{ xs: 12, sm: 18, md: 24, xl: 36 }}>
      <Col sm={24} md={12}>
        <Video
          url="https://youtu.be/LhV67E5BIAk?si=d8pB01pEDCzhFUPd"
          style={{ marginBottom: 30 }}
        />
      </Col>

      
      
   

    
      
    </Row>
    
    
    
    
    
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
